function loadYouTubeAPI() {
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function createPlayer(playerInfo) {
    return new YT.Player(playerInfo.id, {
        videoId: playerInfo.videoId,
        playerVars: {
            showinfo: 0,
        }
    });
}

function stopAllVideosYoutube() {
    window.playersYT.forEach(function (el) {
        el.stopVideo();
    });
}
  
function playAllVideoYoutube() {
    window.playersYT.forEach(function (el) {
        el.mute();
        el.playVideo();
    });
}

window.onYouTubeIframeAPIReady = function() {
    const playerInfoList = [{
        id: 'Video1',
        videoId: 'btKELJP-MjA'
    }];
    // Se eliminó Video2 de esta lista

    const playersYT = [];
    console.log("onYouTubeIframeAPIReady");

    for (let i = 0; i < playerInfoList.length; i++) {
        const curplayer = createPlayer(playerInfoList[i]);
        playersYT[i] = curplayer;
    }

    window.playersYT = playersYT;
    console.log(playersYT);
}

export { loadYouTubeAPI, playAllVideoYoutube, stopAllVideosYoutube };
