import "./styles.css";
import { loadYouTubeAPI, playAllVideoYoutube, stopAllVideosYoutube } from './yt.js';
import { setupVideoWithHLS, setupVideoWithMP4 } from './videoJs.js';
import { GetMap } from './mapa.js';

//se encarga de instancia los reproductores de yt
loadYouTubeAPI();

// Define window.player_js como un objeto vacío
window.player_js = {};

// Ejemplo de uso de las funciones de configuración de video
document.addEventListener('DOMContentLoaded', () => {
    window.player_js.Video4 = setupVideoWithHLS('#Video4', 'https://iptv.ixfo.com.ar:30443/live-HD/C4POS/chunks.m3u8');
    window.player_js.Video6 = setupVideoWithHLS('#Video6', 'https://iptv.ixfo.com.ar:30443/live/c6sd/playlist.m3u8');
    window.player_js.Video7 = setupVideoWithHLS('#Video7', 'https://streamlov.alsolnet.com/misionescuatro/live/playlist.m3u8');
    window.player_js.Video8 = setupVideoWithHLS('#Video8', 'https://iptv.ixfo.com.ar:30443/c21tv/hd/c21tv/playlist.m3u8');
  
    window.player_js.Video3 = setupVideoWithMP4('#Video3', 'https://nd106.republicaservers.com:4433/canal2misioness.mp4');
    cargarVideoCanal12();
    playAllVideos();
});

let canal12Iframe = null;

function cargarVideoCanal12(autoplay = true, mute = true) {
    const container = document.getElementById('canal12-container');
    canal12Iframe = document.createElement('iframe');
    canal12Iframe.src = `https://vmf.edge-apps.net/embed/live.php?streamname=c12_live01-100129&autoplay=${autoplay}&mute=${mute}`;
    canal12Iframe.width = '100%';
    canal12Iframe.height = '100%';
    canal12Iframe.frameBorder = '0';
    canal12Iframe.allowFullscreen = true;
    container.innerHTML = '';
    container.appendChild(canal12Iframe);
}

function playAllVideos() {
    playAllVideoJS();
    playAllVideoYoutube();
    playVideoCanal12();
}

function stopAllVideos() {
    stopAllVideosJS();
    stopAllVideosYoutube();
    stopVideoCanal12();
}

// Exponer las funciones al ámbito global
window.playAllVideos = playAllVideos;
window.stopAllVideos = stopAllVideos;

function playVideoCanal12() {
    if (!canal12Iframe) {
        cargarVideoCanal12(true, true);
    } else {
        canal12Iframe.contentWindow.postMessage('play', '*');
    }
}

function stopVideoCanal12() {
    if (canal12Iframe) {
      cargarVideoCanal12(false, true);
        canal12Iframe.contentWindow.postMessage('pause', '*');
    }
}

window.onload = function() {
  GetMap("myMap",10, [-55.900525, -27.379548]);
    playAllVideos();
  
  };
