
// Función para configurar un video con HLS
function setupVideoWithHLS(id, source) {
    // Selecciona el elemento de video con el ID proporcionado
    const video = document.querySelector(`${id} > video`);

    // Crea un nuevo reproductor Plyr para el video
    const player = new Plyr(video, {
        defaultMuted: true,
        captions: {
            active: true,
            update: true,
            language: 'auto'
        }
    });

    // Verifica si HLS.js es compatible
    if (!Hls.isSupported()) {
        video.src = source; // Establece la fuente del video
    } else {
        // Crea una instancia de Hls
        const hls = new Hls();
        hls.loadSource(source); // Carga la fuente HLS
        hls.attachMedia(video); // Adjunta el video a Hls

        // Maneja el cambio de subtítulos
        player.on('languagechange', () => {
            // El soporte de subtítulos aún es irregular. Ver: https://github.com/sampotts/plyr/issues/994
            setTimeout(() => hls.subtitleTrack = player.currentTrack, 50);
        });
    }

    // Devuelve el reproductor Plyr creado
    return player;
}

// Función para configurar un video con MP4
function setupVideoWithMP4(id, source) {
    // Selecciona el elemento de video con el ID proporcionado
    const video = document.querySelector(`${id} > video`);

    // Crea un nuevo reproductor Plyr para el video
    const player = new Plyr(video, {
        defaultMuted: true,
        captions: {
            active: true,
            update: true,
            language: 'auto'
        }
    });

    // Establece la fuente del video MP4
    video.src = source;

    // Devuelve el reproductor Plyr creado
    return player;
}

function playAllVideoJS() {
    // Itera sobre cada elemento en player_js y los reproduce
    const player_js =window.player_js;
    for (const id in player_js) {
      if (player_js.hasOwnProperty(id)) {
        const player = player_js[id];
        player.muted=true;
        player.play();
      }
    }
  }
  window.playAllVideoJS = playAllVideoJS;

  function stopAllVideosJS() {
  // Itera sobre cada elemento en player_js y los reproduce
  const player_js =window.player_js;
    for (const id in player_js) {
      if (player_js.hasOwnProperty(id)) {
        const player = player_js[id];
        player.stop();
      }
    }
  }
  window.stopAllVideosJS = stopAllVideosJS;
// Exporta las funciones necesarias para que puedan ser utilizadas desde otros archivos
export { setupVideoWithHLS, setupVideoWithMP4 };
